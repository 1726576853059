/*=========================================================================================
  File Name: modulePatientMutations.js
  Description: Patient Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Reservations.unshift(item);
  },
  SET_Patient(state, Reservations) {
    state.Reservations = Reservations;
  },
  UPDATE_Patient(state, Patient) {
    const PatientIndex = state.Reservations.findIndex(p => p.ID == Patient.ID);
    Object.assign(state.Reservations[PatientIndex], Patient);
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Reservations.findIndex(p => p.ID == itemId);
    state.Reservations.splice(ItemIndex, 1);
  },
  SET_ReservationFilterSessionSearchObj(state, ReservationFilterSessionSearchObj) {
    debugger
    state.ReservationFilterSessionSearchObj = ReservationFilterSessionSearchObj;
  }
};
