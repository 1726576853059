<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-1"
      collapse-action
    >
      <div class="vx-row">
        <!-- DateFrom -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date From") }}</label>
          <datepicker
            placeholder="from"
            v-model="ReservationFilterSessionSearch.DateFrom"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>
        <!-- DateTo -->
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
          <label class="text-sm opacity-75">{{ $t("Date To") }}</label>
          <datepicker
            placeholder="from"
            v-model="ReservationFilterSessionSearch.DateTo"
            class="w-full"
            name="to"
          ></datepicker>
        </div>
        <!-- DoctorPreferredLanguageID -->
        <div
          v-show="ReservationFilterSessionSearch.IsPrivate != true"
          class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2"
        >
          <label class="text-sm opacity-75">{{
            $t("DoctorsPreferLanguage")
          }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="ReservationFilterSessionSearch.DoctorPreferredLanguageID"
            :options="languages"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <!-- PatientPreferredLanguageID -->
        <div
          v-show="ReservationFilterSessionSearch.IsPrivate != true"
          class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2"
        >
          <label class="text-sm opacity-75">{{
            $t("PatientPreferLanguage")
          }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="ReservationFilterSessionSearch.PatientPreferredLanguageID"
            :options="languages"
            :reduce="(ID) => ID.ID"
          />
        </div>

        <div class="vx-col w-full">
          <div class="vx-row">
            <vs-button
              style="margin: 10px"
              class="my-3"
              @click="FillterSession"
              v-scroll-to="'#Scroll'"
              >{{ $t("Fillter") }}</vs-button
            >
          </div>
        </div>
      </div>
    </vx-card>

    <vs-table
      id="Scroll"
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="Reservations"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="
              p-4
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                Reservations.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : Reservations.length
              }}
              of {{ Reservations.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="ReservationDate">{{ $t("Appointment Date") }}</vs-th>
        <vs-th sort-key="PatientName">{{ $t("Patient Name") }}</vs-th>
        <vs-th sort-key="PatientLanguage">{{ $t("Patient Language") }}</vs-th>
        <vs-th sort-key="DoctorName">{{ $t("Doctor Name") }}</vs-th>
        <vs-th sort-key="DoctorLanguage">{{ $t("Doctor Language") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
            
            <vs-td>
              <p
                v-if="tr.DoctorSession.DateFrom"
                class="product-name font-medium truncate"
              >
                {{ tr.DoctorSession.DateFrom.replace('T',' ') }}
              </p>
            </vs-td>

            <vs-td>
              <p
                v-if="tr.Patient.Name"
                class="product-name font-medium truncate"
              >
                {{ tr.Patient.Name }}
              </p>
            </vs-td>

            <vs-td>
              <p
                class="product-name font-medium truncate"
                v-if="tr.Patient.PreferredLanguage"
              >
                {{ tr.Patient.PreferredLanguage.NameEN }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.Doctor.NameEN }}
              </p>
            </vs-td>

            <vs-td>
              <div v-for="td in tr.Doctor.DoctorLanguages" :key="td.NameEN">
                <p
                  class="product-name font-medium truncate"
                  v-if="tr.Doctor.DoctorLanguages"
                >
                  <!-- {{ tr.Doctor.PreferredLanguage.NameEN }} -->
                  {{ td.Language.NameEN }}
                </p>
              </div>
            </vs-td>
            
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moduleReservation from "@/store/reservation/FillterResrvationSession/moduleReservation.js";
import Datepicker from "vuejs-datepicker";
import moduleLanguage from "@/store/settings/language/moduleLanguage.js";

export default {
  components: {
    Datepicker,
  },
  data() {
    return {
      selected: [],
      search: {},
      Patients: [],
      Status: [],
      PaymentStatuses: [],
      itemsPerPage: 10,
      isMounted: false,
      rowDataForDelete: {},
      activeConfirm: false,
    };
  },
  computed: {
    ReservationFilterSessionSearch() {
      debugger;
      return this.$store.state.ReservationList
        .ReservationFilterSessionSearchObj;
    },
    languages() {
      return this.$store.state.LanguageList.languages;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    Reservations() {
      return this.$store.state.ReservationList.Reservations;
    },
  },

  methods: {
    resetData() {
      this.ReservationFilterSessionSearch = {};
      this.ReservationFilterSessionSearch.DateFrom =
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-1";

      this.ReservationFilterSessionSearch.DateTo =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate();
    },

    FillterSession() {
      this.$store.dispatch(
        "ReservationList/FillterPatientReservationSessions",
        this.ReservationFilterSessionSearch
      );
    },
  },

  created() {
    if (!moduleReservation.isRegistered) {
      this.$store.registerModule("ReservationList", moduleReservation);
      moduleReservation.isRegistered = true;
    }

    if (!moduleLanguage.isRegistered) {
      this.$store.registerModule("LanguageList", moduleLanguage);
      moduleLanguage.isRegistered = true;
    }
    this.FillterSession();
    this.$store.dispatch("LanguageList/GetAllLanguages");
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
