/*=========================================================================================
  File Name: modulePatientActions.js
  Description: Patient Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
 
export default {

  FillterPatientReservationSessions({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/API/PatientReservationSession/FillterPatientReservationSession",
          item
        )
        .then(response => {
          debugger
         
          response.data.Data
          var PatientReservationSession;

          commit("SET_Patient", response.data.Data);
          debugger
          resolve(response);
          debugger
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
